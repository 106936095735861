import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/layout'

const AllTags = ({ pageContext }) => {
  const { tags } = pageContext

  if (tags) {
    return (
      <Layout>
        <ul>
          {tags.map(tag => {
            return (
              <li>
                <Link to={`/tags/${tag}`}>
                  {tag}
                </Link>
              </li>
            )
          })}
        </ul>
      </Layout>
    )
  }
}

export default AllTags